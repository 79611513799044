import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import IdeetippList from "../components/IdeetippList"
import { Link, graphql } from "gatsby"

const Error = ({
  data: {
    allContentfulIdeetipp: { nodes: ideetipp },
  },
}) => {
  return (
    <Layout>
      <SEO title="404 " />
      <main className="error-page">
        <section>
          <h1>404</h1>
          <h3>Diese Idee haben wir vergessen, aber...</h3>
        </section>
      </main>
      <main className="page">
        <h5>... schau dir gern folgende Tipps an!</h5>
        <IdeetippList ideetipp={ideetipp} />
      </main>
    </Layout>
  )
}

export const data = graphql`
  {
    allContentfulIdeetipp(
      sort: { fields: createdAt, order: DESC }
      filter: { featured: { eq: true } }
      limit: 4
    ) {
      nodes {
        id
        title
        createdAt
        subtitle
        durationTime
        prepTime
        image {
          id
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
`

export default Error
